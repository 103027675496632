var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        size: "md",
        "ok-title": _vm.$t("label.accept"),
        "cancel-title": _vm.$t("label.cancel"),
      },
      on: {
        ok: function ($event) {
          _vm.$emit("success")
          _vm.show = false
        },
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v("\n        " + _vm._s(_vm.$t("label.confirm")) + " "),
        _c("span", { staticClass: "font-weight-light" }, [
          _vm._v(_vm._s(_vm.$t("label.action"))),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v(_vm._s(_vm.$t("misc.unsaved_changes_kicker"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-form-row",
        [
          _c("b-form-group", { staticClass: "col" }, [
            _vm._v(_vm._s(_vm.$t("misc.unsaved_changes_info"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }