var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "app" } },
      [
        _vm.$auth.ready() && _vm.lang_ready ? _c("router-view") : _vm._e(),
        _vm._v(" "),
        _c("notifications", {
          attrs: { group: "notifications", position: "bottom right" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }