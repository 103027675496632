var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("notifications", {
        attrs: {
          group: "error",
          duration: -1,
          position: "top right",
          classes: "vue-notification bg-danger text-white",
        },
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: {
          group: "success",
          duration: 2000,
          position: "top right",
          classes: "vue-notification bg-primary text-white",
        },
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: {
          group: "info",
          duration: 2000,
          position: "top right",
          classes: "vue-notification bg-info text-white",
        },
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: {
          group: "warning",
          duration: 5000,
          position: "top right",
          classes: "vue-notification bg-warning text-dark",
        },
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: {
          group: "default",
          duration: 2000,
          position: "top right",
          classes: "vue-notification bg-white text-dark",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }