var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex justify-content-center" }, [
    !_vm.loaded && !_vm.error
      ? _c("i", {
          staticClass:
            "fas fa-circle-notch fa-spin text-white fa-2x align-self-center",
        })
      : _vm._e(),
    _vm._v(" "),
    !_vm.error
      ? _c("img", {
          attrs: { src: _vm.image },
          on: {
            load: function ($event) {
              _vm.loaded = true
            },
            error: function ($event) {
              _vm.error = true
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }