var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "authentication-wrapper authentication-1 px-4" },
    [
      _c(
        "div",
        { staticClass: "authentication-inner py-5" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center align-items-center" },
            [_c("img", { attrs: { src: _vm.publicUrl + "/img/logo.svg" } })]
          ),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }