var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error
    ? _c("div", { staticClass: "callout callout-danger col" }, [
        _vm.statusCode === 422
          ? _c(
              "div",
              [
                _c(
                  "span",
                  { staticClass: "d-block" },
                  [
                    _vm._v(_vm._s(_vm.title) + " "),
                    _vm.error.data.details
                      ? _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.details",
                                modifiers: { details: true },
                              },
                            ],
                            staticClass: "p-0",
                            attrs: { variant: "link" },
                          },
                          [_vm._v("Detalles")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-collapse",
                  { attrs: { id: "details" } },
                  [
                    _c("hr"),
                    _vm._v(" "),
                    _vm._l(_vm.error.data.details, function (val, key) {
                      return _c(
                        "div",
                        { key: key },
                        _vm._l(val, function (error, index) {
                          return _c(
                            "small",
                            { key: index, staticClass: "d-block" },
                            [_vm._v(_vm._s(error))]
                          )
                        }),
                        0
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm.statusCode === 404
          ? _c(
              "div",
              [
                _c(
                  "span",
                  { staticClass: "d-block" },
                  [
                    _vm._v(_vm._s(_vm.title) + " "),
                    _vm.error.data.details
                      ? _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.details",
                                modifiers: { details: true },
                              },
                            ],
                            staticClass: "p-0",
                            attrs: { variant: "link" },
                          },
                          [_vm._v("Detalles")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error.data.details
                  ? _c("b-collapse", { attrs: { id: "details" } }, [
                      _c("hr"),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.error.data.details) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm.statusCode === 500
          ? _c(
              "div",
              [
                _c(
                  "span",
                  { staticClass: "d-block" },
                  [
                    _vm._v(_vm._s(_vm.title) + " "),
                    _vm.error.data.details
                      ? _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.details",
                                modifiers: { details: true },
                              },
                            ],
                            staticClass: "p-0",
                            attrs: { variant: "link" },
                          },
                          [_vm._v("Detalles")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error.data.details
                  ? _c("b-collapse", { attrs: { id: "details" } }, [
                      _c("hr"),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.error.data.details) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm.statusCode === 403
          ? _c(
              "div",
              [
                _c(
                  "span",
                  { staticClass: "d-block" },
                  [
                    _vm._v(_vm._s(_vm.title) + " "),
                    _vm.error.data.details
                      ? _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.details",
                                modifiers: { details: true },
                              },
                            ],
                            staticClass: "p-0",
                            attrs: { variant: "link" },
                          },
                          [_vm._v("Detalles")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error.data.details
                  ? _c("b-collapse", { attrs: { id: "details" } }, [
                      _c("hr"),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.error.data.details) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "span",
                  { staticClass: "d-block" },
                  [
                    _vm._v(_vm._s(_vm.title) + " "),
                    _vm.error.data.details
                      ? _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.details",
                                modifiers: { details: true },
                              },
                            ],
                            staticClass: "p-0",
                            attrs: { variant: "link" },
                          },
                          [_vm._v("Detalles")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error.data.details
                  ? _c("b-collapse", { attrs: { id: "details" } }, [
                      _c("hr"),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.error.data.details) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }