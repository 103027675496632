var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidenav",
    { class: _vm.curClasses, attrs: { orientation: _vm.orientation } },
    [
      _vm.orientation !== "horizontal"
        ? _c(
            "div",
            { staticClass: "app-brand demo" },
            [
              _c("span", { staticClass: "app-brand-logo demo mt-n1" }, [
                _c("img", {
                  staticClass: "w-100",
                  attrs: { src: _vm.publicUrl + "/img/isologo.svg" },
                }),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "app-brand-text demo sidenav-text font-weight-normal ml-2",
                  attrs: { to: "/" },
                },
                [_vm._v(_vm._s(_vm.$appName))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "layout-sidenav-toggle sidenav-link text-large ml-auto",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleSidenav()
                    },
                  },
                },
                [_c("i", { staticClass: "ion ion-md-menu align-middle" })]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orientation !== "horizontal"
        ? _c("div", { staticClass: "sidenav-divider mt-0" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sidenav-inner",
          class: { "py-1": _vm.orientation !== "horizontal" },
        },
        [
          _c(
            "sidenav-router-link",
            {
              attrs: { icon: "ion ion-md-home", to: "/dashboard", exact: true },
            },
            [_vm._v(_vm._s(_vm.$t("label.home")))]
          ),
          _vm._v(" "),
          !_vm.call.room.id &&
          (_vm.$auth.check("call_view_new") ||
            _vm.$auth.check("call_view_secretary") ||
            _vm.$auth.check("call_view_payment") ||
            _vm.$auth.check("call_view_doctor") ||
            _vm.$auth.check("call_view_finished"))
            ? _c(
                "sidenav-router-link",
                {
                  attrs: {
                    icon: "ion ion-md-call",
                    to: { name: "rooms" },
                    "exact-path": "/rooms",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.calls")))]
              )
            : _vm.call.room.id &&
              (_vm.$auth.check("call_view_new") ||
                _vm.$auth.check("call_view_secretary") ||
                _vm.$auth.check("call_view_payment") ||
                _vm.$auth.check("call_view_doctor") ||
                _vm.$auth.check("call_view_finished"))
            ? _c(
                "sidenav-router-link",
                {
                  attrs: {
                    icon: "ion ion-md-call",
                    to: { name: "call" },
                    "exact-path": "/call",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.calls")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$auth.check("patient_view_all")
            ? _c(
                "sidenav-router-link",
                {
                  attrs: {
                    icon: "fas fa-user-injured",
                    to: "/patients",
                    exact: "",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.patients")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$auth.check("profile_view_patients") ||
          _vm.$auth.check("profile_view_calls") ||
          _vm.$auth.check("profile_view_notes") ||
          _vm.$auth.check("profile_view_prescriptions") ||
          _vm.$auth.check("profile_view_statistics")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "fas fa-chart-line",
                    open: _vm.isMenuOpen("/activity"),
                  },
                },
                [
                  _c("template", { slot: "link-text" }, [
                    _vm._v(_vm._s(_vm.$t("label.my_activity"))),
                  ]),
                  _vm._v(" "),
                  _vm.$auth.check("profile_view_patients")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "activity-patients" },
                            active: _vm.isMenuActive("/activity/patients"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.patients")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("profile_view_calls")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "activity-calls" },
                            active: _vm.isMenuActive("/activity/calls"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.calls")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("profile_view_notes")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "activity-notes" },
                            active: _vm.isMenuActive("/activity/notes"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.notes")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("profile_view_prescriptions")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "activity-prescriptions" },
                            active: _vm.isMenuActive("/activity/prescriptions"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.prescriptions")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("profile_view_statistics")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "activity-statistics" },
                            active: _vm.isMenuActive("/activity/statistics"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.statistics")))]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("sidenav-divider"),
          _vm._v(" "),
          _vm.$auth.check("admin_view") ||
          _vm.$auth.check("secretary_view") ||
          _vm.$auth.check("doctor_view")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "fas fa-users",
                    open:
                      _vm.isMenuOpen("/admins") ||
                      _vm.isMenuOpen("/doctors") ||
                      _vm.isMenuOpen("/secretaries"),
                  },
                },
                [
                  _c("template", { slot: "link-text" }, [
                    _vm._v(_vm._s(_vm.$t("label.draft"))),
                  ]),
                  _vm._v(" "),
                  _vm.$auth.check("admin_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "admins" },
                            active: _vm.isMenuActive("/admins"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.admins")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("doctor_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "doctors" },
                            active: _vm.isMenuActive("/doctors"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.doctors")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("secretary_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "secretaries" },
                            active: _vm.isMenuActive("/secretaries"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.secretaries")))]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$auth.check("city_view") ||
          _vm.$auth.check("state_view") ||
          _vm.$auth.check("country_view") ||
          _vm.$auth.check("agreement_view") ||
          _vm.$auth.check("speciality_view") ||
          _vm.$auth.check("gender_view") ||
          _vm.$auth.check("doctype_view") ||
          _vm.$auth.check("reason_view") ||
          _vm.$auth.check("rate_view")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "fas fa-cog",
                    open:
                      _vm.isMenuOpen("/cities") ||
                      _vm.isMenuOpen("/states") ||
                      _vm.isMenuOpen("/countries") ||
                      _vm.isMenuOpen("/agreements") ||
                      _vm.isMenuOpen("/specialities") ||
                      _vm.isMenuOpen("/genders") ||
                      _vm.isMenuOpen("/doctypes") ||
                      _vm.isMenuOpen("/reasons") ||
                      _vm.isMenuOpen("/rates"),
                  },
                },
                [
                  _c("template", { slot: "link-text" }, [
                    _vm._v(_vm._s(_vm.$t("label.settings"))),
                  ]),
                  _vm._v(" "),
                  _vm.$auth.check("city_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "cities" },
                            active: _vm.isMenuActive("/cities"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("city.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("state_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "states" },
                            active: _vm.isMenuActive("/states"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("state.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("country_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "countries" },
                            active: _vm.isMenuActive("/countries"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("country.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("agreement_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "agreements" },
                            active: _vm.isMenuActive("/agreements"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("agreement.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("speciality_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "specialities" },
                            active: _vm.isMenuActive("/specialities"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("speciality.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("gender_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "genders" },
                            active: _vm.isMenuActive("/genders"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("gender.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("doctype_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "doctypes" },
                            active: _vm.isMenuActive("/doctypes"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("doctype.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("reason_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "reasons" },
                            active: _vm.isMenuActive("/reasons"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("reason.label.plural")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$auth.check("rate_view")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: { name: "rates" },
                            active: _vm.isMenuActive("/rates"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("rate.label.plural")))]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$auth.check("role_view")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "fas fa-shield-alt",
                    open: _vm.isMenuOpen("/roles"),
                  },
                },
                [
                  _c("template", { slot: "link-text" }, [
                    _vm._v(_vm._s(_vm.$t("label.security"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sidenav-router-link",
                    {
                      attrs: {
                        to: { name: "roles" },
                        active: _vm.isMenuActive("/roles"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("role.label.plural")))]
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "small",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$auth.check("profile_set_online"),
              expression: "$auth.check('profile_set_online')",
            },
          ],
          staticClass: "system-info bg-dark w-100 px-4 py-3 text-nowrap",
        },
        [_c("ChangeOnlineStatus")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }