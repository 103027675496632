import { render, staticRenderFns } from "./SidenavDivider.vue?vue&type=template&id=55996e23&"
import script from "./SidenavDivider.vue?vue&type=script&lang=js&"
export * from "./SidenavDivider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55996e23')) {
      api.createRecord('55996e23', component.options)
    } else {
      api.reload('55996e23', component.options)
    }
    module.hot.accept("./SidenavDivider.vue?vue&type=template&id=55996e23&", function () {
      api.rerender('55996e23', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vendor/libs/sidenav/SidenavDivider.vue"
export default component.exports