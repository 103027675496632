var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-wrapper layout-2" },
    [
      _c("nprogress-container"),
      _vm._v(" "),
      _c("CustomNotifications"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "layout-inner" },
        [
          _c("app-layout-sidenav"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "layout-container" },
            [
              _c("app-layout-navbar"),
              _vm._v(" "),
              _c("div", { staticClass: "layout-content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "router-transitions container-fluid flex-grow-1 container-p-y",
                  },
                  [
                    _c("Version"),
                    _vm._v(" "),
                    _c("Logout"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.$root.showFixedVideo &&
                              _vm.room.id &&
                              _vm.room.host_installation_id ===
                                _vm.$auth.user().installation_id,
                            expression:
                              "!$root.showFixedVideo && room.id && room.host_installation_id === $auth.user().installation_id",
                          },
                        ],
                        staticClass: "floating-video-container position-fixed",
                      },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "shadow-lg",
                            attrs: { "no-body": "" },
                          },
                          [
                            _c(
                              "b-card-header",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle.accordion-1",
                                    modifiers: { "accordion-1": true },
                                  },
                                ],
                                staticClass: "p-2 cursor-pointer",
                              },
                              [
                                _vm.room.id
                                  ? _c(
                                      "span",
                                      { staticClass: "text-success" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-circle",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("label.call_in_progress")
                                            )
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.video_streaming"))
                                      ),
                                    ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "fas fa-chevron-up float-right mt-1",
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "fas fa-chevron-down float-right mt-1",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              {
                                attrs: {
                                  id: "accordion-1",
                                  visible: !_vm.collapsed,
                                  accordion: "accordion",
                                },
                              },
                              [
                                _c("b-card-body", { staticClass: "p-0" }, [
                                  _c(
                                    "div",
                                    { attrs: { id: "floating-video-wrapper" } },
                                    [
                                      _c("Video", {
                                        attrs: { id: "video-component" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "keep-alive",
                      { attrs: { include: ["LayoutCall", "Video"] } },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "layout-overlay",
        on: { click: _vm.closeSidenav },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }