var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "b-modal",
        {
          model: {
            value: _vm.logout.modal,
            callback: function ($$v) {
              _vm.$set(_vm.logout, "modal", $$v)
            },
            expression: "logout.modal",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _vm._v("\n            " + _vm._s(_vm.$t("label.confirm")) + " "),
            _c("span", { staticClass: "font-weight-light" }, [
              _vm._v(_vm._s(_vm.$t("label.action"))),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("auth.modal.logout_kicker"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c("b-form-group", { staticClass: "col" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("auth.modal.logout_info")),
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.logout.modal = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "ladda-btn",
                {
                  staticClass: "btn btn-danger",
                  attrs: {
                    loading: _vm.logout.loading,
                    "data-style": "zoom-out",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.doLogout.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.logout")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }