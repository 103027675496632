var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "position-relative" }, [
        _c("div", { staticClass: "col-12" }, [
          !_vm.connection.error
            ? _c("div", { staticClass: "row bg-primary text-white py-1" }, [
                _c("div", { staticClass: "col" }, [
                  _c("small", { staticClass: "d-block" }, [
                    _c("span", { staticClass: "font-weight-semibold" }, [
                      _vm._v(_vm._s(_vm.$t("label.status")) + ":"),
                    ]),
                    _vm._v(" "),
                    _vm.localParticipant.state
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("label." + _vm.localParticipant.state)
                            )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    [
                      "preparing_devices",
                      "connecting",
                      "reconnecting",
                    ].includes(_vm.localParticipant.state)
                      ? _c("span", [
                          _c("i", {
                            staticClass: "fas fa-circle-notch fa-spin",
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    ["connected", "reconnected"].includes(
                      _vm.localParticipant.state
                    ) &&
                    (!_vm.remoteParticipant.state ||
                      ["disconnected"].includes(_vm.remoteParticipant.state))
                      ? _c("span", [
                          _c("span", [
                            _vm._v(
                              " - " +
                                _vm._s(_vm.$t("label.waiting_for_patient"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "font-weight-semibold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.call.patient.id
                                    ? _vm.call.patient.last_name +
                                        " " +
                                        _vm.call.patient.first_name
                                    : _vm.$t("label.unknown")
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          !_vm.remoteParticipant.state ||
                          ["reconnecting", "disconnected"].includes(
                            _vm.remoteParticipant.state
                          )
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fas fa-circle-notch fa-spin",
                                }),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    ["connected", "reconnected"].includes(
                      _vm.localParticipant.state
                    ) &&
                    ["connected", "reconnected"].includes(
                      _vm.remoteParticipant.state
                    )
                      ? _c("span", [
                          _c("span", [_vm._v(_vm._s(_vm.$t("label.with")))]),
                          _vm._v(" "),
                          _c("span", { staticClass: "font-weight-semibold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.call.patient.id
                                    ? _vm.call.patient.last_name +
                                        " " +
                                        _vm.call.patient.first_name
                                    : _vm.$t("label.unknown")
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    ["connected", "reconnected"].includes(
                      _vm.localParticipant.state
                    ) && ["reconnecting"].includes(_vm.remoteParticipant.state)
                      ? _c("span", [
                          _c("span", [_vm._v(" - ")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "font-weight-semibold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.call.patient.id
                                    ? _vm.call.patient.last_name +
                                        " " +
                                        _vm.call.patient.first_name
                                    : _vm.$t("label.unknown")
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("label.is_reconnecting"))),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-circle-notch fa-spin",
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _c("div", { staticClass: "row bg-danger text-white py-1" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("small", { staticClass: "d-block" }, [
                    _c("span", { staticClass: "font-weight-semibold" }, [
                      _vm._v("Error:"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.connection.error)),
                  ]),
                ]),
              ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class:
              _vm.mainTrack === "local"
                ? "primary-video-track"
                : "secondary-video-track",
          },
          [
            _vm.mainTrack === "remote"
              ? _c("div", {
                  staticClass: "toggle-main-track",
                  on: {
                    click: function ($event) {
                      _vm.mainTrack = "local"
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "local-devices-status" },
              [
                _vm.localAudio.error
                  ? _c("small", { staticClass: "d-block my-1 bg-danger" }, [
                      _vm._v(
                        _vm._s(_vm.$t("label.audio_error")) +
                          ": " +
                          _vm._s(_vm.localAudio.error)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.localVideo.error
                  ? _c("small", { staticClass: "d-block my-1 bg-danger" }, [
                      _vm._v(
                        _vm._s(_vm.$t("label.video_error")) +
                          ": " +
                          _vm._s(_vm.localVideo.error)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeRoom && !_vm.activeRoom.participants.size
                  ? _c(
                      "ladda-btn",
                      {
                        staticClass: "btn btn-sm btn-danger my-2 ml-2",
                        attrs: { "data-style": "zoom-out" },
                        nativeOn: {
                          click: function ($event) {
                            _vm.unstart.modal = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.cancel_request")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { attrs: { id: "localTrack" } }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class:
              _vm.mainTrack === "remote"
                ? "primary-video-track"
                : "secondary-video-track",
          },
          [
            _vm.mainTrack === "local"
              ? _c("div", {
                  staticClass: "toggle-main-track",
                  on: {
                    click: function ($event) {
                      _vm.mainTrack = "remote"
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "remote-devices-status" }, [
              _vm.remoteAudio.isEnabled === false
                ? _c("small", { staticClass: "d-block my-1" }, [
                    _vm._v(_vm._s(_vm.$t("label.participant_audio_disabled"))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.remoteVideo.isEnabled === false
                ? _c("small", { staticClass: "d-block my-1" }, [
                    _vm._v(_vm._s(_vm.$t("label.participant_video_disabled"))),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", {
              class: { disabled: !_vm.remoteVideo.isEnabled },
              attrs: { id: "remoteTrack" },
            }),
          ]
        ),
        _vm._v(" "),
        _vm.call.room.id
          ? _c(
              "div",
              {
                staticClass:
                  "video-actions text-white py-1 px-3 d-flex align-items-center",
              },
              [
                _vm.room.id
                  ? _c("div", { staticClass: "participant-name d-block" }, [
                      _vm.call.patient.id
                        ? _c(
                            "div",
                            [
                              _vm.$auth.check("patient_view")
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "text-small text-primary",
                                      attrs: {
                                        to: {
                                          name: "patients-view",
                                          params: {
                                            resourceId: _vm.call.patient.id,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.call.patient.last_name) +
                                          " " +
                                          _vm._s(_vm.call.patient.first_name) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _c("small", { staticClass: "d-block" }, [
                                    _vm._v(
                                      _vm._s(_vm.call.patient.last_name) +
                                        " " +
                                        _vm._s(_vm.call.patient.first_name)
                                    ),
                                  ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "d-block" }, [
                                _vm._v(
                                  "DNI " + _vm._s(_vm.call.patient.doc_number)
                                ),
                              ]),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("small", { staticClass: "d-block" }, [
                              _vm._v(_vm._s(_vm.$t("label.unknown"))),
                            ]),
                            _vm._v(" "),
                            _c("small", { staticClass: "d-block" }, [
                              _vm._v(
                                "DNI " + _vm._s(_vm.call.room.hints.doc_number)
                              ),
                            ]),
                          ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end flex-fill" },
                  [
                    _vm.$root.showFixedVideo
                      ? _c(
                          "div",
                          [
                            !_vm.activeRoom
                              ? _c(
                                  "ladda-btn",
                                  {
                                    staticClass: "btn btn-sm btn-primary",
                                    attrs: {
                                      loading:
                                        _vm.starting || _vm.connection.loading,
                                      disabled:
                                        !_vm.room.id ||
                                        !_vm.mediaReady ||
                                        _vm.connection.loading,
                                      title: _vm.$t("label.start_call"),
                                      "data-style": "zoom-out",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.startRoom.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.connection.error
                                          ? _vm.$t("label.start")
                                          : _vm.$t("label.retry")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-btn",
                              {
                                staticClass: "btn btn-default ml-2",
                                attrs: {
                                  disabled:
                                    !_vm.mediaReady ||
                                    _vm.localVideo.error !== null,
                                  size: "sm",
                                  type: "button",
                                  title: _vm.$t("label.disable_video"),
                                },
                                on: { click: _vm.muteVideo },
                              },
                              [
                                _c("span", { staticClass: "fa-stack fa-xs" }, [
                                  _c("i", {
                                    staticClass: "fas fa-video fa-stack-2x",
                                  }),
                                  _vm._v(" "),
                                  !_vm.localVideo.isEnabled
                                    ? _c("i", {
                                        staticClass: "fas fa-slash fa-stack-2x",
                                      })
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-btn",
                              {
                                staticClass: "btn btn-default ml-2",
                                attrs: {
                                  disabled:
                                    !_vm.mediaReady ||
                                    _vm.localAudio.error !== null,
                                  size: "sm",
                                  type: "button",
                                  title: _vm.$t("label.disable_audio"),
                                },
                                on: { click: _vm.muteAudio },
                              },
                              [
                                _c("span", { staticClass: "fa-stack fa-xs" }, [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-microphone fa-stack-2x",
                                  }),
                                  _vm._v(" "),
                                  !_vm.localAudio.isEnabled
                                    ? _c("i", {
                                        staticClass: "fas fa-slash fa-stack-2x",
                                      })
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-dd",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  disabled: !_vm.room.id,
                                  size: "sm",
                                  dropup: "",
                                  variant: "danger",
                                  text: _vm.$t("label.finish"),
                                  right: true,
                                },
                              },
                              [
                                _c(
                                  "b-dd-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.finish.modal = true
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label.finish")))]
                                ),
                                _vm._v(" "),
                                _vm.$auth.check("call_reject") &&
                                _vm.$auth.check("secretary", "roles")
                                  ? _c(
                                      "b-dd-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.reject.modal = true
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("label.reject")))]
                                    )
                                  : _vm.$auth.check("call_reject") &&
                                    _vm.$auth.check("doctor", "roles")
                                  ? _c(
                                      "b-dd-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.reject.modal = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("label.derive_to_secretary")
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("router-link", {
                              attrs: { to: { name: "call" }, custom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var href = ref.href
                                      var navigate = ref.navigate
                                      return [
                                        _c(
                                          "b-btn",
                                          {
                                            attrs: {
                                              href: href,
                                              size: "sm",
                                              variant: "default",
                                            },
                                            on: { click: navigate },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t("label.view_call")
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3030615011
                              ),
                            }),
                          ],
                          1
                        ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "no-close-on-backdrop": _vm.unstart.noClose,
            "no-close-on-esc": _vm.unstart.noClose,
            "hide-header-close": _vm.unstart.noClose,
          },
          on: {
            hidden: function ($event) {
              Object.assign(_vm.$data.unstart, _vm.$options.data().unstart)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            " + _vm._s(_vm.$t("label.confirm")) + " "
                  ),
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.$t("label.action"))),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "d-block text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("room.modal.unstart_kicker"))),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "secondary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "ladda-btn",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      attrs: {
                        loading: _vm.unstart.loading,
                        "data-style": "zoom-out",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.unstartRoom.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.cancel_request")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.unstart.modal,
            callback: function ($$v) {
              _vm.$set(_vm.unstart, "modal", $$v)
            },
            expression: "unstart.modal",
          },
        },
        [
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("room.modal.unstart_info")))]),
          _vm._v(" "),
          _vm.unstart.error
            ? _c("form-errors-component", {
                staticClass: "mt-3",
                attrs: { error: _vm.unstart.error },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "no-close-on-backdrop": _vm.reject.noClose,
            "no-close-on-esc": _vm.reject.noClose,
            "hide-header-close": _vm.reject.noClose,
          },
          on: {
            hidden: function ($event) {
              Object.assign(_vm.$data.reject, _vm.$options.data().reject)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            " + _vm._s(_vm.$t("label.confirm")) + " "
                  ),
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.$t("label.action"))),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "d-block text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("room.modal.reject_kicker"))),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "secondary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "ladda-btn",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      attrs: {
                        loading: _vm.reject.loading,
                        "data-style": "zoom-out",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.rejectRoom.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.reject")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.reject.modal,
            callback: function ($$v) {
              _vm.$set(_vm.reject, "modal", $$v)
            },
            expression: "reject.modal",
          },
        },
        [
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("room.modal.reject_info")))]),
          _vm._v(" "),
          _vm.reject.error
            ? _c("form-errors-component", {
                staticClass: "mt-3",
                attrs: { error: _vm.reject.error },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "no-close-on-backdrop": _vm.finish.noClose,
            "no-close-on-esc": _vm.finish.noClose,
            "hide-header-close": _vm.finish.noClose,
          },
          on: {
            hidden: function ($event) {
              Object.assign(_vm.$data.finish, _vm.$options.data().finish)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            " + _vm._s(_vm.$t("label.confirm")) + " "
                  ),
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v(_vm._s(_vm.$t("label.action"))),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "d-block text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("room.modal.finish_kicker"))),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "secondary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "ladda-btn",
                    {
                      staticClass: "btn btn-sm btn-danger",
                      attrs: {
                        loading: _vm.finish.loading,
                        "data-style": "zoom-out",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.finishRoom.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("label.finish")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.finish.modal,
            callback: function ($$v) {
              _vm.$set(_vm.finish, "modal", $$v)
            },
            expression: "finish.modal",
          },
        },
        [
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("room.modal.finish_info")))]),
          _vm._v(" "),
          _vm.finish.error
            ? _c("form-errors-component", {
                staticClass: "mt-3",
                attrs: { error: _vm.finish.error },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }