var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.$auth.user()
        ? _c("div", { staticClass: "col-12" }, [
            _c("span", { staticClass: "text-big mr-2" }, [
              _vm._v(_vm._s(_vm.$t("label.accept_incoming_calls")) + ": "),
            ]),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "switcher switcher-sm mt-n1",
                class: _vm.$auth.user().online_status
                  ? "switcher-success"
                  : "switcher-danger switcher-danger-websocket",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$auth.user().online_status,
                      expression: "$auth.user().online_status",
                    },
                  ],
                  staticClass: "switcher-input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.$auth.user().online_status)
                      ? _vm._i(_vm.$auth.user().online_status, null) > -1
                      : _vm.$auth.user().online_status,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeOnlineStatus($event)
                    },
                    change: function ($event) {
                      var $$a = _vm.$auth.user().online_status,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.$auth.user(),
                              "online_status",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.$auth.user(),
                              "online_status",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.$auth.user(), "online_status", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          on: {
            hidden: function ($event) {
              Object.assign(_vm.$data.online, _vm.$options.data().online)
            },
          },
          model: {
            value: _vm.online.modal,
            callback: function ($$v) {
              _vm.$set(_vm.online, "modal", $$v)
            },
            expression: "online.modal",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _vm._v("\n            " + _vm._s(_vm.$t("label.confirm")) + " "),
            _c("span", { staticClass: "font-weight-light" }, [
              _vm._v(_vm._s(_vm.$t("label.action"))),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("auth.modal.set_me_online_kicker"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c("b-form-group", { staticClass: "col" }, [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(_vm._s(_vm.$t("auth.modal.set_me_online_info"))),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("label.ask_continue")))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.online.error
            ? _c("form-errors-component", {
                attrs: { error: _vm.online.error },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.online.modal = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "ladda-btn",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    loading: _vm.online.loading,
                    "data-style": "zoom-out",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.setOnline(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.accept")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          on: {
            hidden: function ($event) {
              Object.assign(_vm.$data.offline, _vm.$options.data().offline)
            },
          },
          model: {
            value: _vm.offline.modal,
            callback: function ($$v) {
              _vm.$set(_vm.offline, "modal", $$v)
            },
            expression: "offline.modal",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _vm._v("\n            " + _vm._s(_vm.$t("label.confirm")) + " "),
            _c("span", { staticClass: "font-weight-light" }, [
              _vm._v(_vm._s(_vm.$t("label.action"))),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("auth.modal.set_me_offline_kicker"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c("b-form-group", { staticClass: "col" }, [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(_vm._s(_vm.$t("auth.modal.set_me_offline_info"))),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("label.ask_continue")))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.offline.error
            ? _c("form-errors-component", {
                attrs: { error: _vm.offline.error },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.offline.modal = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "ladda-btn",
                {
                  staticClass: "btn btn-danger",
                  attrs: {
                    loading: _vm.offline.loading,
                    "data-style": "zoom-out",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.setOffline(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.accept")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          on: {
            hidden: function ($event) {
              Object.assign(_vm.$data.warning, _vm.$options.data().warning)
            },
          },
          model: {
            value: _vm.warning.modal,
            callback: function ($$v) {
              _vm.$set(_vm.warning, "modal", $$v)
            },
            expression: "warning.modal",
          },
        },
        [
          _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _vm._v("\n            " + _vm._s(_vm.$t("label.user")) + " "),
            _c("span", { staticClass: "font-weight-light" }, [
              _vm._v(_vm._s(_vm.$t("label.offline"))),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("auth.modal.warning_online_kicker"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c("b-form-group", { staticClass: "col" }, [
                _c("span", { staticClass: "d-block" }, [
                  _vm._v(_vm._s(_vm.$t("auth.modal.warning_online_info"))),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.online.error
            ? _c("form-errors-component", {
                attrs: { error: _vm.online.error },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "mr-2",
                  attrs: { variant: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.warning.modal = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "ladda-btn",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    loading: _vm.online.loading,
                    "data-style": "zoom-out",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.setOnline(false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("label.connect")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "switcher-indicator cursor-pointer" }, [
      _c("span", { staticClass: "switcher-yes" }),
      _vm._v(" "),
      _c("span", { staticClass: "switcher-no" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }