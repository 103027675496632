var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "layout-navbar align-items-lg-center container-p-x",
      attrs: { toggleable: "lg", variant: _vm.getLayoutNavbarBg() },
    },
    [
      _c(
        "b-navbar-brand",
        {
          staticClass: "app-brand demo d-lg-none py-0 mr-4",
          attrs: { to: "/" },
        },
        [
          _c("span", { staticClass: "app-brand-logo demo" }, [
            _c("img", {
              staticClass: "w-100",
              attrs: { src: _vm.publicUrl + "/img/isologo.svg" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "app-brand-text demo font-weight-normal ml-2" },
            [_vm._v(_vm._s(_vm.$appName))]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.sidenavToggle
        ? _c(
            "b-navbar-nav",
            {
              staticClass:
                "layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto",
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-item nav-link px-0 mr-lg-4 cursor-pointer",
                  on: { click: _vm.toggleSidenav },
                },
                [
                  _c("i", {
                    staticClass: "ion ion-md-menu text-large align-middle",
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "app-layout-navbar" } }),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "app-layout-navbar" } },
        [
          _c("hr", { staticClass: "d-lg-none w-100 my-2" }),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "align-items-lg-center ml-auto" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1",
                },
                [_vm._v("|")]
              ),
              _vm._v(" "),
              _vm.$auth.user()
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "demo-navbar-user",
                      attrs: { right: !_vm.isRtlMode },
                    },
                    [
                      _c("template", { slot: "button-content" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-inline-flex flex-lg-row-reverse align-items-center align-middle",
                          },
                          [
                            _c("img", {
                              staticClass: "d-block ui-w-30 rounded-circle",
                              attrs: { src: _vm.avatar, alt: "" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "px-1 mr-lg-2 ml-2 ml-lg-0" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-block mb-0 mt-n1" },
                                  [_vm._v(_vm._s(_vm.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-capitalize mt-n1 d-block text-info",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("label." + _vm.role)))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.$auth.check("profile_view")
                        ? _c("router-link", {
                            attrs: { to: { name: "profile-view" }, custom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var href = ref.href
                                    var navigate = ref.navigate
                                    return [
                                      _c(
                                        "b-dd-item",
                                        {
                                          attrs: { href: href },
                                          on: { click: navigate },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-user text-lightest",
                                          }),
                                          _vm._v(
                                            "   " +
                                              _vm._s(_vm.$t("label.profile"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2633513321
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-dd-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$root.$emit("requestLogout")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "ion ion-ios-log-out text-danger",
                          }),
                          _vm._v("   " + _vm._s(_vm.$t("label.logout"))),
                        ]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }