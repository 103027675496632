import { render, staticRenderFns } from "./LayoutSidenav.vue?vue&type=template&id=41ad0f14&"
import script from "./LayoutSidenav.vue?vue&type=script&lang=js&"
export * from "./LayoutSidenav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41ad0f14')) {
      api.createRecord('41ad0f14', component.options)
    } else {
      api.reload('41ad0f14', component.options)
    }
    module.hot.accept("./LayoutSidenav.vue?vue&type=template&id=41ad0f14&", function () {
      api.rerender('41ad0f14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/LayoutSidenav.vue"
export default component.exports