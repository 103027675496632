var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showWarning
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "alert alert-warning" }, [
            _c(
              "span",
              { staticClass: "d-block text-big font-weight-semibold" },
              [
                _vm._v(
                  "Existe una nueva versión disponible de la aplicación web."
                ),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _vm._v("Por favor, "),
              _c("a", { attrs: { href: "#" }, on: { click: _vm.refreshApp } }, [
                _vm._v("vuelva a cargar el sitio"),
              ]),
              _vm._v(
                " y limpie el caché de su navegador en caso de encontrarse con errores."
              ),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v("Versión actual: " + _vm._s(_vm.appVersion))]),
            _vm._v(" - "),
            _c("span", [
              _vm._v("Versión disponible: " + _vm._s(_vm.latestVersion)),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }